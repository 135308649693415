<template>
    <div>
        <div class="page-title">自选库</div>

        <div class="mg-titles">

            <div class="mg-groups">


            </div>

            <div class="mode">
                <a-radio-group v-model="thumbMode" default-value="thumb" button-style="solid">
                    <a-radio-button class="btn-sort" value="white_image">
                        透明图
                    </a-radio-button>
                    <a-radio-button class="btn-sort" value="thumb">
                        电商图
                    </a-radio-button>
                    <a-radio-button class="btn-sort" value="multi-map">
                        多图模式
                    </a-radio-button>
                </a-radio-group>
            </div>

        </div>

        <div class="mg-contents">

            <div v-if="loading" class="loading-block">
                <loader/>
            </div>
            <div class="empty" v-else-if="!list.length">
                <a-empty description="暂无任何商品"/>
            </div>
            <div v-else>
                <div class="goods-list">

                    <template v-for="(item,ik) in list">

                        <div class="goods-item" :key="ik">
                            <goods-card :thumb-type="thumbMode" :item="item"></goods-card>
                            <button @click="xuan(item)" class="btn-da"
                                    :class="{selected: selectedGoods.indexOf(item) >= 0 }">选
                            </button>
                        </div>

                    </template>

                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>

                </div>

                <a-affix :offsetBottom="0">
                    <div class="list-footer">
                        <a-pagination
                                class="st-pagination"
                                v-if="pager.total > 0 "
                                :current="pager.current"
                                :total="pager.total"
                                :page-size="pager.size"
                                @change="gotoPage"
                        />

                        <div class="footer-btns">
                            <button @click="fx" class="btn btn-white">反选</button>
                            <button @click="del" class="btn">移除自选库</button>
                        </div>
                    </div>
                </a-affix>
            </div>

        </div>
    </div>
</template>

<script>
    import Loader from "../../../components/Loader";
    import api from "../../../repo/api";
    import GoodsCard from "./GoodsCard";

    export default {
        name: "My",
        components: {GoodsCard, Loader},
        data() {
            return {
                thumbMode: "white_image",
                list: [],
                loading: true,
                query: {
                    page: 1
                },
                pager: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                },
                selectedGoods: []
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            xuan(item) {
                let idx = this.selectedGoods.indexOf(item)

                if (idx >= 0) {

                    this.selectedGoods.splice(idx, 1)

                } else {

                    this.selectedGoods.unshift(item)
                }

            },
            getFirstList() {

            },
            getList() {

                api.get('/ast-goods/goods', this.query, ({data}) => {

                    this.loading = false

                    this.list = data.data
                    this.pager.current = data.current_page
                    this.pager.total = data.total
                    this.pager.size = data.per_page

                })
            },
            gotoPage(page) {
                this.query.page = page
                this.getList()
            },
            fx() {
                let list = []

                this.list.forEach(item => {

                    if (this.selectedGoods.indexOf(item) >= 0) return

                    list.push(item)

                })

                this.selectedGoods = list
            },
            del() {
                let that = this;

                let length = this.selectedGoods.length

                if (length <= 0) return;

                this.$confirm({
                    title: `确定要把这${length}件商品移除自选库？`,
                    content: '此操作不可逆。',
                    onOk() {

                        that.$loading.show()

                        let ids = []

                        that.selectedGoods.forEach(item => ids.push(item.id))

                        api.post('/ast-goods/del', {ids}, (data) => {

                            that.$loading.hide()

                            that.$message.data(data)

                            if (data.code === 0) {
                                that.getList()
                            }

                        })

                    },
                });
            }
        }
    }
</script>

<style lang="less" scoped>

    .mg-titles {
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #EEEEEE;
        padding-left: 25px;
        padding-right: 16px;

        .btn {
            margin-left: 16px;
        }
    }

    .mode .btn-sort {
        margin-right: 0;
        height: 36px;
        line-height: 36px;
    }

    .btn-sort {
        font-size: 14px;
        font-weight: 400;

        border: 1px solid #EEEEEE;
        margin-right: 16px;
        border-radius: 0;
        padding-left: 18px;
        padding-right: 18px;
        height: 32px;
        line-height: 32px;

        /deep/ span {
            color: #999999;
        }

        &:before {
            display: none;
        }

        &.ant-radio-button-wrapper-checked {
            background: #333333;
            border: 1px solid #333333;

            /deep/ span {
                color: #FFFFFF;
            }

            &:hover {
                background-color: #333333;
                border-color: #333333;
            }
        }
    }

    .mg-contents {
        border: 1px solid #EEEEEE;
        border-top: 0;
    }


    .btn-white {

        border: 1px solid #EEEEEE;
        color: #999999;
    }

    .btn-more {
        padding-right: 34px;
        background-image: url("/assets/icons/zx-btn-more.png");
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 50px 10px;
    }

    .btn-new {
        background-image: url("/assets/icons/zx-btn-new.png");
        background-size: 16px;
        background-repeat: no-repeat;
        padding-left: 32px;
        background-position: 8px;
    }

    .list-footer {
        padding: 0 44px 24px;

        display: flex;
        justify-content: space-between;

        .btn-white {
            margin-right: 16px;
        }
    }

    .goods-item {
        width: 200px;
        margin-right: 40px;
        margin-bottom: 40px;
        position: relative;
    }

    .goods-list {
        padding: 24px 44px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -42px;
    }

    .btn-da {
        width: 24px;
        height: 24px;
        background: #333333;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
        position: absolute;
        right: 10px;
        top: 10px;
        border: 0;
        border-radius: 50%;
        cursor: pointer;

        &.selected {
            color: #FFFFFF;
            background: #FFAD00;
        }
    }

    .empty {
        padding: 32px 0 32px 0;
    }
</style>